@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #fbdfcca3;
    margin: 0;
    padding: 0;
    font-family: 'Courier New', Courier, monospace;
}



#root {
    margin: 0;
    padding: 0;
}