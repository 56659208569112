@font-face {
  font-family: hanifah;
  src: url("/public/font/hanifah.ttf") format("truetype");
}


.title {
  font-family: "hanifah";
}

.App, .UploadVideoComponent {
  background-image: url("/public/images/Fondo Oli.png");
  background-size:cover;
}

.soyyoymama {
  background-image: url("/public/images/fondo2.png");
  background-size: cover;
}

#videos {
    background-image: url("/public/images/Fondo Oli.png");
    background-size: cover;
}